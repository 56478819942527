import React from "react"

import Layout from "../components/layout-pt"
import SEO from "../components/seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const ContactsPage = () => (
  <Layout>
    <SEO title="Contactos" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Contacte-nos</h1>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column" />
        <div class="column is-5">
          <div class="content>">
            Para reservas e outras questões, contacte-nos através de:
            <br />
            <br />
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faEnvelope} />
              <a href="mailto:campingsilveira@gmail.com">
                &nbsp;campingsilveira@gmail.com
              </a>
            </h1>
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;+351 917 741 173
            </h1>
            <h1 class="title is-size-5">
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;+351 962 387 266
            </h1>
            <p><strong>A receção está aberta das 9:00 às 20:00.</strong></p>
            <br></br>
            <p>
              Na estação alta é preferível chegar no início da tarde para ter a
              certeza de encontrar lugar. Temos um número limitado de alvéolos,
              o parque de campismo enche-se rapidamente, e só aceitamos reservas
              para uma estadia mínima de uma semana.
            </p>
            <br />
            <p>
              O Parque de Campismo Rural da Silveira é recomendado por numerosos
              guias internacionais, entre os quais o Guide du Routard, o Petit
              futé o guia Vert Michelin, SVR, Rustiek Kamperen e a ANWB.
            </p>
          </div>
        </div>
        <div class="column" />
      </div>
    </div>
  </Layout>
)

export default ContactsPage
